import React, { useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

import SEO from "../seo"

import "./_leadership.scss"
import { detectMob, useInput } from "@src/Helper"

function Leadership() {
  const images = useStaticQuery(graphql`
    {
      yey1: file(relativePath: { eq: "logos/commission-image-1.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      yey2: file(relativePath: { eq: "logos/commission-image-2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      yey3: file(relativePath: { eq: "logos/commission-image-3.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      graph1: file(relativePath: { eq: "backgrounds/commission-guide-1.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      graph2: file(relativePath: { eq: "backgrounds/commission-guide-2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      guideJasmine: file(relativePath: { eq: "bots/guide-jasmine.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      guideSophie: file(relativePath: { eq: "bots/guide-sophie.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      guideTheo: file(relativePath: { eq: "bots/guide-theo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      guideCamilla: file(relativePath: { eq: "bots/guide-camilla.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  const id = useInput("leadership")

  useEffect(() => {
    if (detectMob()) {
      id.setValue("m-leadership")
    }
  }, [])

  return (
    <div id={id.value}>
      <SEO title="Leadership" />
      <div className="leadership-container">
        <div className="banner">
          <div className="banner-card">
            <div className="caption m-0">EXM Guide</div>
            <hr className="separator-mafia-primary" />
            <div className="title text-navy-blue">Affiliate Commission</div>
          </div>
        </div>

        <section className="section-top-50">
          <div className="container">
            <div className="text-center mb-5">
              <div className="heading text-navy-blue mb-2">
                How Much Affiliate Commission Can I Earn?
              </div>
              <div className="subcaption">
                You can earn up to{" "}
                <span className="text-navy-blue text-700">59% commissions</span>{" "}
                for EXM's Affiliate Marketing Program (from your paid/subscribed
                referrals)
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-12 col-sm-6 col-md-4">
                <div className="d-flex flex-column align-items-center text-center px-3">
                  <div style={{ width: 250 }} className="mb-1">
                    <Img fluid={images.yey1.childImageSharp.fluid} />
                  </div>
                  <div className="caption text-400">
                    <strong className="text-navy-blue">40%</strong> from Direct
                    Commissions over{" "}
                    <strong className="text-navy-blue">5 levels.</strong>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-md-4">
                <div className="d-flex flex-column align-items-center text-center px-3">
                  <div style={{ width: 250 }} className="mb-1">
                    <Img fluid={images.yey2.childImageSharp.fluid} />
                  </div>
                  <div className="caption text-400">
                    <strong className="text-navy-blue">9%</strong> from
                    Leadership Commission over{" "}
                    <strong className="text-navy-blue">4 levels.</strong>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-md-4">
                <div className="d-flex flex-column align-items-center text-center px-3">
                  <div style={{ width: 250 }} className="mb-1">
                    <Img fluid={images.yey3.childImageSharp.fluid} />
                  </div>
                  <div className="caption text-400">
                    <strong className="text-navy-blue">10%</strong> from EXM’s{" "}
                    <Link
                      className="text-marine-aqua underline"
                      to="/leaderboard"
                    >
                      Leaderboard
                    </Link>{" "}
                    over
                    <strong className="text-navy-blue"> 5 ranks.</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id="direct-commission"
          className="section-top-50 section-bottom-50"
        >
          <div className="container">
            <div className="text-center">
              <div className="title decorated-title text-navy-blue mb-3">
                Direct Commission
              </div>
              <div className="subcaption mb-4">
                We really appreciate everyone who helps us grow the community.
                On that note, we are giving out commissions according to your
                hard work! Earn up to{" "}
                <span className="text-navy-blue text-700">40% commissions</span>{" "}
                for every member that subscribes directly under your referral.
              </div>
            </div>

            <div className="my-5">
              <table className="leadership-table ">
                <tr>
                  <th>Level</th>
                  <th>Status</th>
                  <th>Comission Rate</th>
                  <th>Requirement</th>
                </tr>
                <tr>
                  <td className="text-700 text-navy-blue">1</td>
                  <td>Associate</td>
                  <td className="text-700 text-navy-blue">20%</td>
                  <td>{`< 3 Personal Recruits`}</td>
                </tr>
                <tr>
                  <td className="text-700 text-navy-blue">2</td>
                  <td>Made Man</td>
                  <td className="text-700 text-navy-blue">25%</td>
                  <td>3-9 Personal Recruits</td>
                </tr>
                <tr>
                  <td className="text-700 text-navy-blue">3</td>
                  <td>Underboss</td>
                  <td className="text-700 text-navy-blue">30%</td>
                  <td>10-24 Personal Recruits</td>
                </tr>
                <tr>
                  <td className="text-700 text-navy-blue">4</td>
                  <td>Boss</td>
                  <td className="text-700 text-navy-blue">35%</td>
                  <td>25-49 Personal Recruits</td>
                </tr>
                <tr>
                  <td className="text-700 text-navy-blue">5</td>
                  <td>Consigliere</td>
                  <td className="text-700 text-navy-blue">40%</td>
                  <td> &gt;= 50 Personal Recruits</td>
                </tr>
              </table>
            </div>

            <div className="sub-caption text-meteoroid-purple mb-5">
              <span className="text-700">
                <sup>*</sup>Note
              </span>
              : You don't have to be subscribed to EXM Subscription plan to earn
              Direct Commission. <br />
              <span className="text-700">
                <sup>**</sup>Note
              </span>
              : You will still get the Direct Commission whenever your direct
              referrals choose to extend their subscription in EXM.
            </div>

            <div className="text-center">
              <div className="caption mb-0">Simple Illustration of</div>
              <div className="subtitle line-height-auto text-navy-blue">
                Direct Sponsor Commission
              </div>
              <hr className="separator-mafia-primary" />
            </div>

            <div className="my-5" style={{ maxWidth: 800, margin: "auto" }}>
              <Img fluid={images.graph1.childImageSharp.fluid} />
            </div>

            <div className="subcaption mb-5">
              Assuming everyone has the{" "}
              <span className="text-navy-blue text-700">Manager Package</span>{" "}
              as their subscription plans, here is how much commissions{" "}
              <span className="text-navy-blue text-700">Jasmine</span> will earn
              from{" "}
              <span className="text-navy-blue text-700">Direct Commission</span>
              :
            </div>

            <div className="card card-secondary my-3">
              <div className="leadership-calculation-container">
                <div className="leadership-profile-image">
                  <Img
                    fluid={images.guideJasmine.childImageSharp.fluid}
                    alt="Jasmine"
                  />
                </div>
                <div className="ml-0 ml-lg-4 w-100">
                  <div className="mb-3">
                    Jasmine has{" "}
                    <span className="text-navy-blue text-700">
                      3 direct referrals
                    </span>{" "}
                    under her, therefore her position is in{" "}
                    <span className="text-navy-blue text-700">Level 2</span>,
                    her status is{" "}
                    <span className="text-navy-blue text-700">Made Man</span>,
                    and the commission rate she will receive is{" "}
                    <span className="text-navy-blue text-700">25%</span>.
                  </div>

                  <div className="d-flex align-items-center formula-container mb-2 flex-wrap">
                    <div className="d-flex align-items-center formula-parenthesed">
                      <div>Total Direct Referrals</div>
                      <div className="formula-operator mx-3">X</div>
                      <div>Subscription Fee</div>
                    </div>

                    <div className="formula-operator mx-3">X</div>

                    <div>Commission Rate</div>
                  </div>

                  <div className="text-meteoroid-purple">
                    <span className="formula-operator">= (</span> 3{" "}
                    <span className="formula-operator">X</span> $27,99{" "}
                    <span className="formula-operator">) X</span> 25%
                  </div>
                  <div className="text-meteoroid-purple">
                    <span className="formula-operator">= </span> $83,97{" "}
                    <span className="formula-operator">X</span> 25%
                  </div>
                  <div className="text-meteoroid-purple">
                    <span className="formula-operator">= </span> $20,99
                  </div>

                  <div className="mt-3">
                    Jasmine will earn{" "}
                    <span className="text-navy-blue text-700">$20.99</span> from
                    EXM's Direct Commission (excluding PayPal fee).
                  </div>
                </div>
              </div>
            </div>
            <div className="sub-caption text-meteoroid-purple mb-5">
              <sup>*</sup>The Commission Rate you will get depends on your{" "}
              <span className="text-navy-blue text-700">level</span> and{" "}
              <span className="text-navy-blue text-700">status</span>. The
              higher your commission rate, the higher commission you will earn.
            </div>

            {/* <div className="card card-secondary my-3">
              <div className="leadership-calculation-container">
                <div className="leadership-profile-image">
                  <Img
                    fluid={images.guideTheo.childImageSharp.fluid}
                    alt="Theo"
                  />
                </div>
                <div className="ml-0 ml-lg-4 w-100">
                  <div className="mb-3">
                    As for Theo with the family status of Under Boss and having
                    10 personal recruits under him, he will get 30% commission
                    from those personal recruits. Here are the complete
                    calculation of the commission Theo earned:
                  </div>

                  <div className="d-flex align-items-center formula-container mb-2">
                    <div className="d-flex align-items-center formula-parenthesed">
                      <div>
                        Total New or Extend
                        <br />
                        Direct Sponsor Commission
                      </div>
                      <div className="formula-operator mx-3">X</div>
                      <div>Number of Membership</div>
                    </div>

                    <div className="formula-operator mx-3">X</div>

                    <div>Family Status</div>
                  </div>

                  <div className="text-meteoroid-purple">
                    <span className="formula-operator">= (</span> 10{" "}
                    <span className="formula-operator">X</span> $27,99{" "}
                    <span className="formula-operator">) X</span> 30%
                  </div>
                  <div className="text-meteoroid-purple">
                    <span className="formula-operator">= </span> $279,90{" "}
                    <span className="formula-operator">X</span> 30%
                  </div>
                  <div className="text-meteoroid-purple">
                    <span className="formula-operator">= </span> $83,97
                  </div>
                </div>
              </div>
            </div>

            <div className="card card-secondary my-3">
              <div className="leadership-calculation-container">
                <div className="leadership-profile-image">
                  <Img
                    fluid={images.guideSophie.childImageSharp.fluid}
                    alt="Theo"
                  />
                </div>
                <div className="ml-0 ml-lg-4 w-100">
                  <div className="mb-3">
                    As for Sophie with the family status of Under Boss and
                    having 6 personal recruits under her, she will get 25%
                    commission from those personal recruits. Here are the
                    complete calculation of the commission Sophie earned:
                  </div>

                  <div className="d-flex align-items-center formula-container mb-2">
                    <div className="d-flex align-items-center formula-parenthesed">
                      <div>
                        Total New or Extend
                        <br />
                        Direct Sponsor Commission
                      </div>
                      <div className="formula-operator mx-3">X</div>
                      <div>Number of Membership</div>
                    </div>

                    <div className="formula-operator mx-3">X</div>

                    <div>Family Status</div>
                  </div>

                  <div className="text-meteoroid-purple">
                    <span className="formula-operator">= (</span> 6{" "}
                    <span className="formula-operator">X</span> $27,99{" "}
                    <span className="formula-operator">) X</span> 25%
                  </div>
                  <div className="text-meteoroid-purple">
                    <span className="formula-operator">= </span> $167,94{" "}
                    <span className="formula-operator">X</span> 25%
                  </div>
                  <div className="text-meteoroid-purple">
                    <span className="formula-operator">= </span> $41,985
                  </div>
                </div>
              </div>
            </div>

            <div className="card card-secondary my-3">
              <div className="leadership-calculation-container">
                <div className="leadership-profile-image">
                  <Img
                    fluid={images.guideCamilla.childImageSharp.fluid}
                    alt="Theo"
                  />
                </div>
                <div className="ml-0 ml-lg-4 w-100">
                  <div className="mb-3">
                    As for Camilla with the family status of Under Boss and
                    having 3 personal recruits under her, she will get 25%
                    commission from those personal recruits. Here are the
                    complete calculation of the commission Camilla earned:
                  </div>

                  <div className="d-flex align-items-center formula-container mb-2">
                    <div className="d-flex align-items-center formula-parenthesed">
                      <div>
                        Total New or Extend
                        <br />
                        Direct Sponsor Commission
                      </div>
                      <div className="formula-operator mx-3">X</div>
                      <div>Number of Membership</div>
                    </div>

                    <div className="formula-operator mx-3">X</div>

                    <div>Family Status</div>
                  </div>

                  <div className="text-meteoroid-purple">
                    <span className="formula-operator">= (</span> 3{" "}
                    <span className="formula-operator">X</span> $27,99{" "}
                    <span className="formula-operator">) X</span> 25%
                  </div>
                  <div className="text-meteoroid-purple">
                    <span className="formula-operator">= </span> $87,97{" "}
                    <span className="formula-operator">X</span> 25%
                  </div>
                  <div className="text-meteoroid-purple">
                    <span className="formula-operator">= </span> $20,9925
                  </div>
                </div>
              </div>
            </div> */}

            <hr className="separator-mafia-primary my-5" />

            <div className="text-center mt-5">
              <div className="heading decorated-title text-navy-blue mb-4">
                Leadership Commission
              </div>
              <div className="subcaption">
                In EXM, you will be encouraged to be a leader for your team. You
                will hold the responsibility to help your referrals grow along
                the way. Therefore, we will reward you with up to{" "}
                <span className="text-navy-blue text-700">9% commissions</span>{" "}
                from Leadership Commission if you can turn your referrals as
                leaders too.
              </div>
            </div>

            <div className="my-5">
              <table className="leadership-table ">
                <tr>
                  <th>Level</th>
                  <th>Comission Rate</th>
                  <th>Requirement</th>
                </tr>
                <tr>
                  <td className="text-700 text-navy-blue">1</td>
                  <td className="text-700 text-navy-blue">3%</td>
                  <td>6-14 Extensions under you</td>
                </tr>
                <tr>
                  <td className="text-700 text-navy-blue">2</td>
                  <td className="text-700 text-navy-blue">5%</td>
                  <td>15-39 Extensions under you</td>
                </tr>
                <tr>
                  <td className="text-700 text-navy-blue">3</td>
                  <td className="text-700 text-navy-blue">7%</td>
                  <td>40-99 Extensions under you</td>
                </tr>

                <tr>
                  <td className="text-700 text-navy-blue">4</td>
                  <td className="text-700 text-navy-blue">9%</td>
                  <td>100 &gt; Extensions under you</td>
                </tr>
              </table>
            </div>

            <div className="sub-caption text-meteoroid-purple mb-5">
              <span className="text-700">
                <sup>*</sup>Note
              </span>
              : You have to be subscribed to one of EXM Subscription plan and
              refer at least 2 direct referrals every month to be able to earn
              the Leadership Commission. <br />
              <span className="text-700">
                <sup>**</sup>Note
              </span>
              : Extensions mean the referrals under you should extend their
              subscription in EXM (New members are excluded). <br />
              <span className="text-700">
                <sup>***</sup>Note
              </span>
              : The Leadership Commission will be calculated monthly from every
              26th to 25th each month.
            </div>

            <div className="text-center">
              <div className="caption mb-0">Simple Illustration of</div>
              <div className="subtitle line-height-auto text-navy-blue">
                Leadership Commission
              </div>
              <hr className="separator-mafia-primary" />
            </div>

            <div className="my-5" style={{ maxWidth: 800, margin: "auto" }}>
              <Img fluid={images.graph2.childImageSharp.fluid} />
            </div>

            <div className="subcaption">
              Assuming everyone are extending their{" "}
              <span className="text-navy-blue text-700">Manager Package</span>,
              here is how much commissions{" "}
              <span className="text-navy-blue text-700">Jasmine</span> will earn
              from{" "}
              <span className="text-navy-blue text-700">
                Leadership Commission
              </span>{" "}
              :
            </div>

            <h3 className="text-navy-blue heading text-center mt-5">Step 01</h3>
            <div className="card card-secondary my-3">
              <div className="leadership-calculation-container">
                <div className="leadership-profile-image">
                  <Img
                    fluid={images.guideJasmine.childImageSharp.fluid}
                    alt="Jasmine"
                  />
                </div>
                <div className="ml-0 ml-lg-4 w-100">
                  <div className="mb-3">
                    Jasmine has{" "}
                    <span className="text-navy-blue text-700">
                      22 extensions
                    </span>{" "}
                    under her, therefore the commission rate she will receive is{" "}
                    <span className="text-navy-blue text-700">5%</span>.
                  </div>

                  <div className="d-flex align-items-center formula-container mb-2 flex-wrap">
                    <div className="d-flex align-items-center formula-parenthesed">
                      <div>Total Extensions</div>
                      <div className="formula-operator mx-3">X</div>
                      <div>Subscription Fee</div>
                    </div>

                    <div className="formula-operator mx-3">X</div>

                    <div>Commission Rate</div>
                  </div>

                  <div className="text-meteoroid-purple">
                    <span className="formula-operator">= (</span>22{" "}
                    <span className="formula-operator">X</span> $27,99{" "}
                    <span className="formula-operator">) X</span> 5%
                  </div>
                  <div className="text-meteoroid-purple">
                    <span className="formula-operator">= </span> $615,78{" "}
                    <span className="formula-operator">X</span> 5%
                  </div>
                  <div className="text-meteoroid-purple">
                    <span className="formula-operator">= </span> $30,78
                  </div>
                </div>
              </div>
            </div>

            <h3 className="text-navy-blue heading text-center mt-5">Step 02</h3>
            <div className="card card-secondary my-3">
              <div className="leadership-calculation-container">
                <div className="leadership-profile-image">
                  <Img
                    fluid={images.guideJasmine.childImageSharp.fluid}
                    alt="Jasmine"
                  />
                </div>
                <div className="ml-0 ml-lg-4 w-100">
                  <div className="mb-3">
                    However, since{" "}
                    <span className="text-navy-blue text-700">
                      Theo (10 referrals)
                    </span>{" "}
                    and{" "}
                    <span className="text-navy-blue text-700">
                      Sophie (6 referrals)
                    </span>{" "}
                    are also qualified for the Leadership Commission,
                    adjustments to Jasmine's Leadership Commission will be made.
                  </div>

                  <div className="d-flex align-items-center formula-container mb-2 flex-wrap">
                    <div className="d-flex align-items-center">
                      <div>Jasmine's Leadership Commission</div>
                      <div className="formula-operator mx-3">-</div>
                      <div className="text-navy-blue">
                        Theo's Leadership Commission
                      </div>
                    </div>

                    <div className="formula-operator mx-3">-</div>

                    <div className="text-navy-blue">
                      Sophie's Leadership Commission
                    </div>
                  </div>

                  <div className="text-meteoroid-purple">
                    <span className="formula-operator">= </span>$30.78{" "}
                    <span className="formula-operator">- </span>
                    <span className="text-navy-blue text-700">(10</span>{" "}
                    <span className="formula-operator text-navy-blue text-700">
                      {" "}
                      X
                    </span>{" "}
                    <span className="text-navy-blue text-700">$27.99</span>
                    <span className="formula-operator text-navy-blue text-700">
                      {" "}
                      X
                    </span>{" "}
                    <span className="text-navy-blue text-700">3% )</span>{" "}
                    <span className="formula-operator">- </span>
                    <span className="text-navy-blue text-700">( 6</span>
                    <span className="formula-operator text-navy-blue text-700">
                      X{" "}
                    </span>
                    <span className="text-navy-blue text-700">$27.99</span>
                    <span className="formula-operator text-navy-blue text-700">
                      X{" "}
                    </span>
                    <span className="text-navy-blue text-700">3% )</span>
                  </div>
                  <div className="text-meteoroid-purple">
                    <span className="formula-operator">= </span> $30,78{" "}
                    <span className="formula-operator">- </span>
                    <span className="text-navy-blue text-700">$8,39 </span>
                    <span className="formula-operator">- </span>
                    <span className="text-navy-blue text-700">$5,03</span>
                  </div>
                  <div className="text-meteoroid-purple">
                    <span className="formula-operator">= </span> $17,36
                  </div>
                </div>
              </div>
              <div className="mt-3">
                Jasmine will earn{" "}
                <span className="text-navy-blue text-700">$17.36</span> from
                EXM's Leadership Commission (excluding PayPal fee).
              </div>
            </div>

            <div className="sub-caption text-meteoroid-purple mb-5">
              <sup>*</sup>
              All the commission you earn through EXM's Affiliate Commission
              will be delivered to your{" "}
              <span className="text-navy-blue text-700">
                EXM Wallet within 7-Day work.
              </span>{" "}
              You can check your EXM Wallet{" "}
              <Link className="underline text-dolphin-blue" to="exm-wallet">
                here
              </Link>
            </div>

            {/* <div className="card card-secondary my-3">
              <div className="leadership-calculation-container">
                <div className="leadership-profile-image">
                  <Img
                    fluid={images.guideSophie.childImageSharp.fluid}
                    alt="Theo"
                  />
                </div>
                <div className="ml-0 ml-lg-4 w-100">
                  <div className="mb-3">
                    As for Sophie with the family status of Under Boss and
                    having 6 personal recruits under her, she will get 25%
                    commission from those personal recruits. Here are the
                    complete calculation of the commission Sophie earned:
                  </div>

                  <div className="d-flex align-items-center formula-container mb-2">
                    <div>
                      <span className="formula-operator">(</span> Total Extend{" "}
                      <span className="formula-operator mx-3">X</span> Qualified
                      Tier <span className="formula-operator">)</span>
                    </div>
                    <div className="formula-operator mx-3">-</div>
                    <div>
                      Members that qualify for the Leadership Commission
                    </div>
                  </div>

                  <div className="text-meteoroid-purple">
                    <span className="formula-operator">= </span> 10 Extends{" "}
                    <span className="formula-operator">X</span> 3%
                  </div>
                  <div className="text-meteoroid-purple">
                    <span className="formula-operator">= (</span> 10{" "}
                    <span className="formula-operator">X</span> $27,99{" "}
                    <span className="formula-operator">) X</span> 3%
                  </div>
                  <div className="text-meteoroid-purple">
                    <span className="formula-operator">= </span> $279,90
                    <span className="formula-operator"> X </span>3%
                  </div>
                  <div className="text-meteoroid-purple">
                    <span className="formula-operator">= </span>$8,397
                  </div>
                </div>
              </div>
            </div>

            <div className="card card-secondary my-3">
              <div className="leadership-calculation-container">
                <div className="leadership-profile-image">
                  <Img
                    fluid={images.guideCamilla.childImageSharp.fluid}
                    alt="Theo"
                  />
                </div>
                <div className="ml-0 ml-lg-4 w-100">
                  <div className="mb-3">
                    As for Camilla with the family status of Under Boss and
                    having 3 personal recruits under her, she will get 25%
                    commission from those personal recruits. Here are the
                    complete calculation of the commission Camilla earned:
                  </div>

                  <div className="d-flex align-items-center formula-container mb-2">
                    <div>
                      <span className="formula-operator">(</span> Total Extend{" "}
                      <span className="formula-operator mx-3">X</span> Qualified
                      Tier <span className="formula-operator">)</span>
                    </div>
                    <div className="formula-operator mx-3">-</div>
                    <div>
                      Members that qualify for the Leadership Commission
                    </div>
                  </div>

                  <div className="text-meteoroid-purple">
                    <span className="formula-operator">= </span> 6 Extends{" "}
                    <span className="formula-operator">X</span> 3%
                  </div>
                  <div className="text-meteoroid-purple">
                    <span className="formula-operator">= (</span> 6{" "}
                    <span className="formula-operator">X</span> $27,99{" "}
                    <span className="formula-operator">) X</span> 3%
                  </div>
                  <div className="text-meteoroid-purple">
                    <span className="formula-operator">= </span> $167,94
                    <span className="formula-operator"> X </span>3%
                  </div>
                  <div className="text-meteoroid-purple">
                    <span className="formula-operator">= </span>$5,0382
                  </div>
                </div>
              </div>
            </div> */}
          </div>

          <hr className="separator-mafia-primary my-5" />
        </section>
      </div>
    </div>
  )
}

export default Leadership
